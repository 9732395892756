import axios from "axios";

export async function fetchDataGetReq(url: any) {
  const headers: any = {
    // ContentType: "application/json;charset=UTF-8",
    // ContentType: "multipart/form-data",
    Accept: "application/json",
  };
  return await axios
    .get(url, headers)
    .then((res) => {
      console.log("get", res);
      return res.data;
    })
    .catch((error) => {
      console.log("get err", error);
      throw error;
    });
}

export async function fetchDataPutReq(url: any, data: any) {
  const headers: any = {
    Accept: "application/json",
    ContentType: "multipart/form-data",
  };
  return await axios
    .put(url, data, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("put err", error);
      throw error;
    });
}

export async function fetchDataPostReq(url: any, data: any) {
  const headers: any = {
    Accept: "application/json",
    ContentType: "multipart/form-data",
  };
  return await axios
    .post(url, data, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
}
