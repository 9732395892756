import { ConfigProvider } from "antd";
import "./App.css";
import AppLayout from "./layout/layout";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#00b96b',
        borderRadius: 2,
        // Alias Token
        colorBgContainer: '#f6ffed',
        colorLink: '#00A4A4',
        colorText: '#1a7da9',
        fontSize: 10,
      },
      components: {
        Layout: {
          headerHeight:35,
          footerPadding:'10px 10px',
        },
        Menu: {
          darkItemBg: '#ffffff',
          darkItemColor: '#1a7da9',
          darkItemHoverColor: '#1a7da9',
          darkItemSelectedBg: '#00A4A4',
          itemHoverColor: '#00A4A4',
          itemColor: '#ffffff',
          itemHeight:1
        },
      }
    }}
  >
      <Router>
        <AppLayout />
      </Router>
    </ConfigProvider>
  );
}

export default App;
