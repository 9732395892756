import ReactPlayer from "react-player";

type Prop = {
  url: any;
};

const VideoPlayer: React.FC<Prop> = (props) => {
  return (
    <>
      <ReactPlayer url={props.url} controls />
    </>
  );
};

export default VideoPlayer;
