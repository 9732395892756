import { Layout, Menu, theme } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppRoutes from "../routes/route";
import { DashboardOutlined, UserOutlined } from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";

const { Header, Content } = Layout;

const getKeyFromPath = (path: string) => {
  switch (path) {
    case "/":
      return "1";
    case "/Frame":
      return "2";
    case "/Account":
      return "6";
    default:
      return "0"; // Default to "0" if no match
  }
};

const AppLayout: React.FC = () => {
  const { pathname } = useLocation();
  const [defaultSelectedKeys] = useState([getKeyFromPath(pathname)]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const content = [
    {
      key: "1",
      icon: <DashboardOutlined />,
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: "2",
      icon: <UserOutlined />,
      label: <Link to="/Frame">Frame</Link>,
    },
    {
      key: "6",
      icon: <UserOutlined />,
      label: <Link to="/Account">Account</Link>,
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          padding: "0px 20px 0px 20px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1a7da9",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={defaultSelectedKeys}
          items={content}
          style={{
            flex: 1,
            minWidth: 0,
            justifyContent: "end",
            background: "#1a7da9",
          }}
        />
      </Header>
      <Content
        style={{
          background: colorBgContainer,
          minHeight: "83vh",
          padding: 24,
          borderRadius: borderRadiusLG,
        }}
      >
        <AppRoutes />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Orange ©{new Date().getFullYear()} Created by NaveeN
      </Footer>
    </Layout>
  );
};

export default AppLayout;
