import { useEffect, useState } from "react";
import fileService from "../../service/fileService";
import { Col, List, Row, Space, Tooltip, Typography } from "antd";
import { FILE_BASE_URI } from "../../utils/constant";
import { PlayCircleOutlined } from "@ant-design/icons";
import VideoPlayer from "./videoPlayer";

const Dashboard: React.FC = () => {
  const [moviesList, setMoviesList] = useState<any[] | []>([]);

  useEffect(() => {
    fileService.getMovieDirList().then((res) => {
      setMoviesList(res);
    });
  }, []);

  // const DownloadFile = (strDocPath: string, strDocName: string) => {
  //   fileService.getFileDownload(strDocPath).then((res) => {
  //     const byteCharacters = atob(res?.byteFileData); // Decode the Base64 string

  //     // Convert the decoded string into a byte array
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);

  //     // Create a Blob with the correct MIME type for a PDF
  //     const blob = new Blob([byteArray], {
  //       type: "application/*",
  //     });

  //     // Create a download link for the Blob
  //     const downloadUrl = URL.createObjectURL(blob);
  //     let a = document.createElement("a");
  //     a.href = downloadUrl;
  //     a.download = res?.strDocName;
  //     a.click();

  //     // Clean up
  //     URL.revokeObjectURL(downloadUrl);
  //   });
  // };

  // const DownloadLargeFile = (strDocPath: string, strDocName: string) => {
  //   fileService.getLargeFileDownload(strDocPath).then((res) => {
  //     // Create a URL from the file blob
  //     const url = window.URL.createObjectURL(new Blob([res.data]));

  //     // Create a link element
  //     const link: any = document.createElement("a");
  //     link.href = url;

  //     // Set the file name
  //     link.setAttribute("download", strDocName); // You can customize the file name here

  //     // Append to the document
  //     document.body.appendChild(link);

  //     // Programmatically click the link to trigger the download
  //     link.click();

  //     // Clean up
  //     link.parentNode.removeChild(link);
  //   });
  // };

  const DownloadFile = (strDocName: string) => {
    window.location.href = FILE_BASE_URI+strDocName;
  };

  const Play = (strDocName: string) => {
    window.location.href = FILE_BASE_URI+strDocName;
    <VideoPlayer url={FILE_BASE_URI+strDocName}/>
  };

  return (
    <>
      <List
        pagination={{ position: "bottom", align: "start", pageSize: 40 }}
        dataSource={moviesList.map((field, index) => ({
          ...field,
          key: index,
        }))}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              description={
                <Row>
                  <Col span={16}>
                    <Space size={8}>
                      <Tooltip color="#00A4A4" title={item.strDocFullName}>
                        <Typography.Link
                          onClick={() =>
                            DownloadFile(item.strOriginalDocName)
                          }
                          strong
                          style={{ color: "#00A4A4", cursor: "pointer" }}
                        >
                          {item.strDocName}
                        </Typography.Link>
                      </Tooltip>
                    </Space>
                  </Col>
                  <Col span={2}>
                  <PlayCircleOutlined onClick={() =>
                            Play(item.strOriginalDocName)} />
                  </Col>
                  <Col span={2}>
                    <Typography.Text strong>{item.strFileSize}</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>
                      {item.dtLastModifiedDate}
                    </Typography.Text>
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default Dashboard;
