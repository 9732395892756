import { Route, Routes } from "react-router-dom";
import ErrorPage from "../views/error/ErrorPage";
import Dashboard from "../views/dashboard/dashboard";
import Frame from "../views/frame/frame";

const AppRoutes: React.FC = () => {
    return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Frame" element={<Frame />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    );
  };
  
  export default AppRoutes;