import { SERVICE_BASE_URI } from "./../utils/constant";
import { fetchDataGetReq } from "../utils/apiUtils";

class FileService {
  getMovieDirList() {
    const url = SERVICE_BASE_URI + `files`;
    return fetchDataGetReq(url);
  }
  getFileDownload(data: any) {
    const url = SERVICE_BASE_URI + `download?filePath=` + btoa(data);
    return fetchDataGetReq(url);
  }

  getLargeFileDownload(data: any) {
    const url = SERVICE_BASE_URI + `downloadFile?filePath=` + btoa(data);
    return fetchDataGetReq(url);
  }
}

const fileService = new FileService();
export default fileService;
